import React from "react";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WelcomePage from "./pages/WelcomePage";
import FeaturedServices from "./pages/FeaturedServices";
import NewHirestlyl from "./pages/NewHirestlyl";
import Experience from "./pages/Experience";
import Banner from "./pages/Banner";
import { Helmet } from "react-helmet";

const Salon = () => {
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
          {/* <link rel="icon" href="./images/favicon.png" /> */}
          <title>HealthCare || Lookwell-parlour </title>
        </Helmet>
        <Navbar />
        <Banner />
        <WelcomePage />
        <FeaturedServices />
        <NewHirestlyl />
        <Experience />
        <Footer />
      </div>
    </>
  );
};

export default Salon;
