import React from "react";
import fourth from "../images/fourth.png";
import first1 from "../images/first1.png";
import second from "../images/second.png";
import third from "../images/third.png";

const Coaches = () => {
  return (
    <>
      <div className="items-center text-center text-black mt-[10px] font-bold">
        <p className="text-lg">SHAPE YOUR BODY</p>
        <h1 className="text-4xl">OUR COACHES</h1>
      </div>
      <div className="flex md:flex-nowarp flex-wrap gap-5 mt-8 p-3 box-border w-[90%] mx-auto">
        <div id="a" className="horizontal_div w-[100%] md:w-[23%]">
          <div className="top1 w-[100%] ">
            <img
              className="images min-w-[300px] max-w-[400px]"
              width="100%"
              height="100%"
              src={first1}
              alt=""
            />
          </div>
          <div className="bottom1 pl-[5%] mt-4">
            <p className="text-gery">Owner / Head Coach</p>
            <h1 className="text-lg font-bold">Mark Brook</h1>
            <p className="mt-3 text-gery">
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia. It is a paradisematic country, in
              which roasted parts of sentences fly into your mouth.
            </p>
          </div>
        </div>
        <div id="b" className="horizontal_div w-[100%] md:w-[23%]">
          <div className="bottom1 mt-4">
            <p className="text-gery">Owner / Head Coach</p>
            <h1 className="text-lg font-bold">Sarah Henderson</h1>
            <p className="mt-3 text-gery">
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia. It is a paradisematic country, in
              which roasted parts of sentences fly into your mouth.
            </p>
          </div>
          <div className="top1 mt-4 w-[100%]">
            <img
              width="100%"
              height="100%"
              className="images"
              src={second}
              alt=""
            />
          </div>
        </div>
        <div id="c" className="horizontal_div w-[100%] md:w-[23%]">
          <div className="top1 w-[100%]">
            <img
              width="100%"
              height="100%"
              className="images"
              src={third}
              alt=""
            />
          </div>
          <div className="bottom1 mt-4">
            <p className="text-gery">Owner / Head Coach</p>
            <h1 className="text-lg font-bold">George Hump</h1>
            <p className="mt-3 text-gery">
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia. It is a paradisematic country, in
              which roasted parts of sentences fly into your mouth.
            </p>
          </div>
        </div>
        <div id="d" className="horizontal_div w-[100%] md:w-[23%]">
          <div className="bottom1 mt-4">
            <p className="text-gery">Owner / Head Coach</p>
            <h1 className="text-lg font-bold">Victor Hump</h1>
            <p className="mt-3 text-gery">
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia. It is a paradisematic country, in
              which roasted parts of sentences fly into your mouth.
            </p>
          </div>
          <div className="top1 mt-4 w-[100%]">
            <img
              className="images"
              width="100%"
              height="100%"
              src={fourth}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Coaches;
