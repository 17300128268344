import React from "react";
import { Link } from "react-router-dom";

const Notfound = () => {
  return (
    <div
      className="bg-black md:h-[40rem] 
    h-96"
    >
      {/* Header start  */}
      <div
        className="selection:bg-Green selection:text-white mx-auto w-full md:h-[35rem] 
     h-96 py-10 px-4 bg-Error bg-contain bg-no-repeat md:bg-top bg-center"
      >
        {/* Content Section */}
        <div className="flex flex-col justify-center text-center items-center md:h-[30rem] h-96 mx-auto">
          <h1 className="text-white uppercase font-bold py-2 md:text-4xl text-xl mb-3">
            404 page
          </h1>
        </div>
        {/* Content Section Ends*/}
      </div>
      <div className=" text-white flex justify-center">
        <h1 className="bg-blue-500  md:px-5 px-2 md:py-2 py-2 rounded-xl">
          <Link to="/">Back To Home</Link>
        </h1>
      </div>

      {/* Header End  */}
    </div>
  );
};

export default Notfound;
