import React from "react";
import Navbar from "./component/Navbar";
import Shap from "./component/Shap";
import Centermulty from "./component/Centermulty";
import About from "./component/About";
import Coaches from "./component/Coaches";
import Gymclass from "./component/Gymclass";
import Btnprogram from "./component/Btnprogram";
import Plant from "./component/Plant";
import Stories from "./component/Stories";
import Blog from "./component/Blog";
import Appointment from "./component/Appointment";
import Footer from "./component/Footer";
import { Helmet } from "react-helmet";

const Gym = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>HealthCare || Gym </title>
      </Helmet>
      <div>
        <Navbar />
        <Shap />
        <Centermulty />
        <About />
        <Coaches />
        <Gymclass />
        <Btnprogram />
        <Plant />
        <Stories />
        <Blog />
        <Appointment />
        <Footer />
      </div>
    </>
  );
};

export default Gym;
