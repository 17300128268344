import "./App.css";
// import Home from "./Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Healthcare from "./Healthcare";
// import Barbista from "./barbistan/Barbista";
// import Gym from "./cerboysgym/Gym";
import Healthcare from "./Healthcare";
import Salon from "./girlsalon/Salon";
import Barbista from "./barbistan/Barbista";
import Gym from "./cerboysgym/Gym";
import Notfound from "./Notfound";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route excat path="/fitness-gym" element={<Gym />} />
          <Route excat path="/lookwell-parlour" element={<Salon />} />
          <Route excat path="/mens-hair-salon" element={<Barbista />} />
          <Route excat path="/" element={<Healthcare />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
