import React from "react";
import Banner from "./components/Banner";
// import Navbar from "./components/Navbar";
import Types from "./components/Types";
import Famus from "./components/Famus";
import Fanny from "./components/Fanny";
import Package from "./components/Package";
import Blog from "./components/Blog";
import Footer from "./components/Footer";
import Helmet from "react-helmet";

const Barbista = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>HealthCare || Barbista </title>
      </Helmet>
      <div className="">
        {/* <Navbar /> */}
        <Banner />
        <Types />
        <Famus />
        <Fanny />
        <Package />
        <Blog />
        <Footer />
      </div>
    </>
  );
};

export default Barbista;
