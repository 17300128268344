import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer mt-44  bg-black ">
        <div className="footer mt-44 w-[90%] mx-auto flex flex-wrap md:flex-nowrap justify-evenly  bg-black py-20 text-gery gap-10">
          <div className="footer_bottom md:w-[25%] w-[100%]">
            <h3 className="text-2xl font-bold mb-3">ABOUT US</h3>
            <p className="text-[13px]">
              CERBOSYS is a software development company that provides
              customized and creative services for online businesses. The
              services we provide have been instrumental in helping shape
              several start-up companies by meeting their changing needs.
            </p>
          </div>
          <div className="footer_bottom md:w-[25%] w-[100%]">
            <h3 className="text-2xl font-bold mb-3">RecentBlog</h3>
            <p className="text-[13px]">
              Even the all powerful Pointing has no control about
            </p>
            <p className="text-[13px]">
              Even the all powerful Pointing has no control about
            </p>
          </div>
          <div className="footer_bottom md:w-[25%] w-[100%]">
            <h3 className="text-2xl font-bold mb-3">services</h3>
            <p className="text-[13px]">Boost Your Body</p>
            <p className="text-[13px]">Achieve Your Goal</p>
            <p className="text-[13px]">Improve Your Performance</p>
          </div>
          <div className="footer_bottom md:w-[25%] w-[100%]">
            <p className="text-[13px]">Address: 15-16 Lakshya Vihar Indore</p>
            <p className="text-[13px]">Tel: 0731 499 3444</p>
            <p className="text-[13px]">My Account</p>
            <p className="text-[13px]">info@cerbosys.com</p>
            <p className="text-[13px]">website: www.cerbosys.com</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
