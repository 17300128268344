import React from "react";
import first from "../images/first.png";

const Shap = () => {
  return (
    <>
      <div className="flex md:flex-nowrap items-center flex-wrap justify-center mt-5 w-[80%] mx-auto p-5">
        <div className="imagebox_1 md:w-[50%] w-[100%]">
          <img src={first} className="w-full" alt="" />
        </div>
        <div className="description  md:w-[50%] w-[100%]">
          <div className="headingfirst">
            <p className="text-md font-subheading">SHAPE YOUR BODY</p>
            <h1 className="font-bold text-3xl mt-3">WHAT WE DO?</h1>
          </div>

          <div className="diffrent_section gap-4 mt-5 flex md:flex-nowarp flex-wrap w-[100%]  justify-between items-center ">
            <div className="sm:w-[15%] w-[100%]">
              <div className="colorbox  min-w-[80px] min-h-[80px] bg-gradient-to-r from-redd via-purple-500 to-purperl"></div>
            </div>
            <div className="textarea sm:w-[75%] w-[100%]">
              <h1 className="font-bold text-xl">ANALYZE YOUR GOAL</h1>
              <p className="font-subheading leading-5 mt-2">
                A small river named Duden flows by their place and supplies it
                with the necessary regelialia. It is a paradisematic country
              </p>
            </div>
          </div>
          <div className="diffrent_section gap-4 mt-3 flex md:flex-nowarp flex-wrap   justify-between items-center ">
            <div className="sm:w-[15%] w-[100%]">
              <div className="colorbox  min-w-[80px] min-h-[80px] bg-gradient-to-r from-redd via-purple-500 to-purperl"></div>
            </div>
            <div className="textarea sm:w-[75%] w-[100%]">
              <h1 className="font-bold text-xl">Work Hard On It</h1>
              <p className="font-subheading leading-5 mt-2">
                A small river named Duden flows by their place and supplies it
                with the necessary regelialia. It is a paradisematic country
              </p>
            </div>
          </div>
          <div className="diffrent_section gap-4 mt-3 flex md:flex-nowarp flex-wrap  justify-between items-center ">
            <div className="sm:w-[15%] w-[100%]">
              {" "}
              <div className="colorbox  min-w-[80px] min-h-[80px] bg-gradient-to-r from-redd via-purple-500 to-purperl "></div>
            </div>
            <div className="textarea sm:w-[75%] w-[100%]">
              <h1 className="font-bold text-xl">Improve Your Performance</h1>
              <p className="font-subheading leading-5 mt-2">
                A small river named Duden flows by their place and supplies it
                with the necessary regelialia. It is a paradisematic country
              </p>
            </div>
          </div>

          <div className="diffrent_section gap-4 mt-3 flex md:flex-nowarp flex-wrap  justify-between items-center ">
            <div className="sm:w-[15%] w-[100%]">
              {" "}
              <div className="colorbox  min-w-[80px] min-h-[80px] bg-gradient-to-r from-redd via-purple-500 to-purperl "></div>
            </div>
            <div className="textarea sm:w-[75%] w-[100%]">
              <h1 className="font-bold text-xl">Achieve Your Perfect Body</h1>
              <p className="font-subheading leading-5 mt-2">
                A small river named Duden flows by their place and supplies it
                with the necessary regelialia. It is a paradisematic country
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shap;
