import React from "react";

const Plant = () => {
  return (
    <>
      <div>
        <div className="text-center mt-16 font-bold">
          <h4 className="text-lg">Pricing Tables</h4>
          <h1 className="text-4xl mt-4">MEMBERSHIP PLANS</h1>
        </div>
        <div className="flex md:flex-nowrap flex-wrap justify-evenly items-center mt-8">
          <div className="table text-center">
            <h4 className="text-md font-bold">One day Training</h4>
            <h1 className="color text-xl font-bold">$7</h1>
            <p className="text-gery">100% free froever</p>
            <button className="btn bg-gradient-to-r text-white from-redd  to-purperl w-[250px] h-[55px] border-0 text-center mt-3">
              GET STARTED
            </button>
            <h3 className="text-md font-bold my-4">ENJOY ALL THE FEATURE</h3>
            <p className="text-gery ">One time access to all club</p>
            <p className="text-gery  my-4">Group Trainer</p>
            <p className="text-gery">Book a Group className</p>
            <p className="text-gery  my-4">Fitness Orientation</p>
          </div>
          <div className="table text-center">
            <h4 className="text-md font-bold">PAY EVERY MONTH</h4>
            <h1 className="color text-xl font-bold">$68</h1>
            <p className="text-gery">All freature are included</p>
            <button className="btn text-white bg-gradient-to-r from-redd  to-purperl w-[250px] h-[55px] border-0 text-center mt-3">
              GET STARTED
            </button>
            <h3 className="text-md font-bold my-4"> ENJOY ALL THE FEATURE</h3>
            <p className="text-gery">Group classNamees</p>
            <p className="text-gery  my-4">Discuss Fitness Goals</p>
            <p className="text-gery">Group Trainer</p>
            <p className="text-gery  my-4">Fitness Orientation</p>
          </div>
          <div className="table text-center">
            <h4 className="text-md font-bold">1 YEAR MEMBERSHIP</h4>
            <h1 className="color text-xl font-bold">$125</h1>
            <p className="text-gery">All freature are included</p>
            <button className="btn text-white bg-gradient-to-r from-redd  to-purperl w-[250px] h-[55px] border-0 text-center mt-3">
              GET STARTED
            </button>
            <h3 className="text-md font-bold my-4">ENJOY ALL THE FEATURE</h3>
            <p className="text-gery">Group classNamees</p>
            <p className="text-gery  my-4">Discuss Fitness Goals</p>
            <p className="text-gery">Group Trainer</p>
            <p className="text-gery  my-4">Fitness Orientation</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plant;
