import React from "react";
import gallery3 from "../images/gallery3.png";
import cycle from "../images/cycle.png";
import gallery4 from "../images/gallery4.png";
import gallery2 from "../images/gallery2.png";
import instagram from "../images/instagram.png";
const Appointment = () => {
  return (
    <>
      <div>
        <div className="h-[440px] flex flex-wrap md:flex-nowrap w-[100%] mt-[90px] bg-gradient-to-r from-orange orange to-pinkdark">
          <div className="cycleimage md:w-[50%] w-[100%] ">
            <img src={cycle} className="w-full min-h-full" alt="" />
          </div>
          <div className="form md:w-[50%] w-[100%] flex justify-center md:visible invisible items-start text-white flex-col pl-[10%]">
            <h1 className="text-3xl font-bold mb-7">Book a Appointment</h1>
            <div className="firstname text-white">
              <input
                className="form_name  h-[30px] bg-transparent text-white border-b-[1px] border-b-gery focus:outline-0 placeholder:text-white placeholder:text-[13px]"
                placeholder="First Name"
                type="text"
              />
              <input
                className="form_name  h-[30px] bg-transparent text-white focus:outline-0 border-b-[1px] border-b-gery placeholder:text-white placeholder:text-[13px]"
                placeholder="Last Name"
                type="text"
              />
            </div>
            <div className="form_dates">
              <input
                className="form_name  h-[30px] bg-transparent text-white focus:outline-0 border-b-[1px] border-b-gery placeholder:text-white placeholder:text-[13px]"
                placeholder="Date"
                type="text"
              />
              <input
                className="form_name  h-[30px] bg-transparent text-white focus:outline-0  border-b-[1px] border-b-gery placeholder:text-white placeholder:text-[13px]"
                placeholder="Time"
                type="text"
              />
              <input
                className="form_name  h-[30px] bg-transparent text-white focus:outline-0  border-b-[1px] border-b-gery placeholder:text-white placeholder:text-[13px]"
                placeholder="Phone"
                type="text"
              />
            </div>
            <div className="inpt_btn">
              <input
                className="form_name  h-[30px] bg-transparent text-white  focus:outline-0 border-b-[1px] border-b-gery placeholder:text-white"
                type="text"
              />
              <button className="abtn text-[13px] font-heading bg-white text-center text-pinkdark bottom-0 h-[30px] w-[180px]">
                Appointment
              </button>
            </div>
          </div>
        </div>
        {/* padding-bottom: 200px; */}
        <div className="flex flex-wrap justify-between gap-10 ">
          <div className="gallery w-[300px] h-[200px] max-h-auto">
            <img src={instagram} alt="" className="w-full h-full" />
          </div>
          <div className="gallery w-[300px] h-[200px] max-h-auto">
            <img src={gallery2} alt="" className="w-full h-full" />
          </div>
          <div className="gallery w-[300px] h-[200px] max-h-auto">
            <img src={gallery3} alt="" className="w-full h-full " />
          </div>
          <div className="gallery w-[300px] h-[200px] max-h-auto">
            <img src={gallery4} className="w-full h-full" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Appointment;
