import React from "react";

const Package = () => {
  return (
    <>
      <div className=" mt-12">
        <div className="seventext items-center mx-auto w-fit">
          <h1 className="text-2xl font-bold w-fit mx-auto">
            Choose Your Package
          </h1>
          <p className="w-fit mx-auto mt-3 items-center">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua ad minim
            veniam
          </p>
        </div>

        <div className="flexdiv gap-2 mt-5 flex md:flex-nowrap flex-wrap  justify-center  pl-[20%] pr-[20%] align-middle text-sm ">
          <div className="scroolfirst py-5">
            <div className="section1 ">
              <h1 className="text-2xl font-bold">Basic Hair Cut & Shave</h1>
              <p className="w-fit mx-auto mt-4">S t a n d a r d Pa c k a g e</p>
            </div>
            <div className="mt-4 section2  border-t-2  border-b-2 border-yellow">
              <h1 className="text-2xl w-fit mx-auto font-bold my-4">$79</h1>
            </div>
            <div className="section3">
              <h3 className="text-md w-fit mx-auto mt-2">Basic hair Cut</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Shave</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Head Wash</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Body Massage</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Snacks</h3>
              <button className="btn2 border-0 h-[30px] mt-4 w-[100px] mx-auto block text-white rounded-[15px] bg-graydark text-sm">
                ORDER NOW
              </button>
            </div>
          </div>
          <div className="scroolsecond bg-graydark text-white py-5">
            <div className="section1 px-5">
              <h1 className="text-2xl font-bold w-fit mx-auto">
                Premium Hair Cut & Shave
              </h1>
              <p className="w-fit mx-auto mt-4">S t a n d a r d Pa c k a g e</p>
            </div>
            <div className="mt-4 section2  border-t-2  border-b-2 border-yellow">
              <h1 className="text-2xl w-fit mx-auto font-bold my-4">$89</h1>
            </div>
            <div className="section3 pb-[20px]">
              <h3 className="text-md w-fit mx-auto mt-2">Basic hair Cut</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Shave</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Head Wash</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Body Massage</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Snacks</h3>
              <button className="btn3 bg-yellow text-[10px] border-0 h-[30px] w-[100px] text-white rounded-[15px] mt-4 mx-auto block">
                ORDER NOW
              </button>
            </div>
          </div>
          <div className="scroolfirst py-5">
            <div className="section1">
              <h1 className="text-2xl font-bold">Luxury Hair Cut & Shave</h1>
              <p className="w-fit mx-auto mt-4">S t a n d a r d Pa c k a g e</p>
            </div>
            <div className="mt-4 section2  border-t-2  border-b-2 border-yellow">
              <h1 className="text-2xl w-fit mx-auto font-bold my-4">$99</h1>
            </div>
            <div className="section3 pb-[20px]">
              <h3 className="text-md w-fit mx-auto mt-2">Basic hair Cut</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Shave</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Head Wash</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Body Massage</h3>
              <h3 className="text-md w-fit mx-auto mt-2">Basic Snacks</h3>
              <button className="btn2 mt-4 border-0 h-[30px] w-[100px] mx-auto block text-white rounded-[15px] bg-graydark text-sm">
                ORDER NOW
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Package;
