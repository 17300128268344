import React from "react";
import div from "../images/div.png";
import about from "../images/about.png";

const Banner = () => {
  return (
    <>
      <div class=" w-full h-[70vh] bg-barbistbg bg-cover flex justify-center items-center">
        <div class="box text-white items-center mx-auto md:w-[50%] w-[100%] ">
          <h1 className="text-white items-center mx-auto w-fit text-4xl font-bold">
            For All Occasion
          </h1>
          <h1 className="text-white w-fit mx-auto text-3xl font-bold mt-2">
            Hair Style is a Must Try Fashion
          </h1>
          <p className="text-white mt-3 w-fit text-sm items-center mb-2">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod temp or incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim.
          </p>
          <div class="circle bg-yellow w-[40px] h-[40px] rounded-[40px] ml-[45%] flex justify-center items-center text-xl">
            <i class="bi bi-play-circle"></i>
          </div>
          <h3 className="text-white items-center mx-auto mt-3 w-fit text-4xl font-bold">
            WATCH INTRO VIDEO
          </h3>
        </div>
      </div>
      <div class="mt-12 flex justify-center items-center mx-auto w-[80%]">
        <div class="box2 flex md:flex-nowrap flex-wrap justify-center items-center">
          <div class="contentbox pb-[10px]">
            <h1 className="text-4xl font-semibold">
              We Believe that Interior beauty Lasts Long
            </h1>
            <p className="mt-4">
              inappropriate behavior is often laughed off as “boys will be
              boys,” women face higher conduct standards especially in the
              workplace. That’s why it’s crucial that as women.
            </p>
            <button class="btn mt-3 bg-yellow border-0 text-md h-[30px] w-[100px] text-white rounded-[15px]">
              Learn More
            </button>
          </div>
          <div class="imagebox flex relative">
            <img class="backgimage" src={div} width="100%" alt="" />
            {/* <div class="secondimage p-[30%] absolute ml-250px] mt-[150px] border-2-Yellow">
              <img class="lastimg" src={about} width="140px" alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
