import React from "react";

const Btnprogram = () => {
  return (
    <>
      <div className="">
        <button className="lastbutton text-white w-[200px] text h-[40px] mt-3 border-0 text-center bg-gradient-to-r from-redd  to-purperl mx-auto block">
          VIEW MORE PROGRAM
        </button>
      </div>
    </>
  );
};

export default Btnprogram;
