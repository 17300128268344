import React from "react";
import text_a from "../images/text_a.png";
import text_b from "../images/text_b.png";
import text_c from "../images/text_c.png";
import text_d from "../images/text_d.png";

const GymclassName = () => {
  return (
    <>
      <div className="text-center mt-8">
        <p className="text-lg">Gym classNamees</p>
        <h1 className="text-4xl">WORKOUT classNameES</h1>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap gap-10 flex-shrink w-[90%] mx-auto mt-12">
        <div className="a_one">
          <div className="a_one_img min-w-[200px] min-h-[200px] max-w-[300px] max-h-[300px] ">
            <img src={text_a} width="100%" height="100%" alt="" />
          </div>
          <div className="a_text">
            <h3 className="text-lg font-bold">Body Building</h3>
            <p>
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia
            </p>
          </div>
        </div>
        <div className="a_one">
          <div className="a_one_img min-w-[200px] min-h-[200px] max-w-[300px] max-h-[300px]">
            <img src={text_b} width="100%" height="100%" alt="" />
          </div>
          <div className="a_text mt-4">
            <h3 className="text-lg font-bold">Aerobic classNamees</h3>
            <p>
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia.
            </p>
          </div>
        </div>
        <div className="a_one">
          <div className="a_one_img min-w-[200px] min-h-[200px] max-w-[300px] max-h-[300px]">
            <img src={text_c} width="100%" height="100%" alt="" />
          </div>
          <div className="a_text">
            <h3 className="text-lg font-bold">Weight Lifting</h3>
            <p>
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia.
            </p>
          </div>
        </div>
        <div className="a_one">
          <div className="a_one_img min-w-[200px] min-h-[200px] max-w-[300px] max-h-[300px]">
            <img src={text_d} width="100%" height="100%" alt="" />
          </div>
          <div className="a_text">
            <h3 className="text-lg font-bold">Yoga classNamees</h3>
            <p>
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GymclassName;
