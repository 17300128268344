import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";

const Footer = () => {
  return (
    <>
      {/* flex-nowrap  md:flex-wrap  */}
      <div className="footer flex flex-wrap  md:flex-nowrap  justify-evenly py-20 px-3  bg-dark text-white mt-12 pt-5">
        <div className="lines md:w-[20%] w-[100%]  ">
          <h1 className="text-3xl font-bold my-4">About Me</h1>
          <p className="text-[14px]">
            Do you want to be even more successful? Learn to love learning and
            growth. The more effort you put into improving your skills
          </p>
          <p className="text-[12px] mt-3">
            Copyright ©2022 All rights reserved
          </p>
        </div>
        <div className="lines md:w-[20%] w-[100%]  ">
          <h1 className="text-3xl font-bold my-4">Newsletter</h1>
          <p className="text-[14px] mb-3">updated with our latest trends</p>

          <input
            type="email"
            placeholder="Enter Your Email Address"
            className="bg-graydark px-2 focus:outline-none"
          />
          <button className="btnl bg-yellow h-[25px] w-[25px] absolute mr-[7px] text-white text-[20px]">
            {/* <i className="bi bi-arrow-right"></i> */}
            <AiOutlineArrowRight />
          </button>
        </div>
        <div className="lines md:w-[20%] w-[100%]   ">
          <h1 className="text-3xl font-bold my-4">Follow Me</h1>
          <p className="text-[14px]">Let us be social</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
