import React from "react";
import one from "../images/one.png";
import two from "../images/two.png";
import three from "../images/three.png";

const Famus = () => {
  return (
    <>
      <div className="mt-5">
        <div className="head items-center pl-[20%] pr-[20%]">
          <h1 className="text-2xl font-bold w-fit mx-auto items-center">
            We Have All Famous Barbers
          </h1>
          <p className="w-fit mx-auto items-center mt-4 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua ad minim
            veniam
          </p>
        </div>
        <div className="imgflex mt-6 flex flex-wrap justify-between pl-[20%] pr-[20%] text-sm">
          <div className="a">
            <img src={two} width="200px" alt="" />

            <h1 className="mt-3 text-xl font-bold">Peter Baker</h1>
            <p className="mt-3  text-sm">
              H e a d h a i r C u t S p e c i a l i s t
            </p>
          </div>
          <div className="b">
            <img src={three} width="200px" alt="" />
            <h1 className="mt-3 text-xl font-bold">Nancy Holmes</h1>
            <p className="mt-3  text-sm">
              S pa & M a k e u p S p e c i a l i s t
            </p>
          </div>
          <div className="c">
            <img src={one} width="200px" alt="" />
            <h1 className="mt-3 text-xl font-bold">Gavin Hansen</h1>
            <p className="mt-3  text-sm">H a i r S t y l i n g E x p e r t</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Famus;
