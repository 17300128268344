import React from "react";
import circle1 from "../images/circle1.png";
import circle2 from "../images/circle2.png";

const Stories = () => {
  return (
    <>
      <div>
        <div className="text-center mt-12">
          <h3 className="text-lg">T e s t i m o n y</h3>
          <h1 className="text-4xl mt-4">Successful Stories</h1>
        </div>
        <div className="flex md:flex-nowarp flex-wrap w-[90%] mx-auto mt-10">
          <div className="story flex flex-col w-[100%] md:w-[33%]">
            <div className="storytext w-[100%] text-center">
              <p className="text-start">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
            </div>
            <div className="storyimage flex justify-evenly items-center mt-3">
              <div className="circle w-[100px] h-[100px] rounded-[25px]">
                <img src={circle1} width="100%" height="100%" alt="" />
              </div>
              <div className="imagestory">
                <h3 className="text-md font-bold">Kenny Bufer</h3>
                <p className="mt-2">Customer</p>
              </div>
            </div>
          </div>
          <div className="story flex flex-col w-[100%] md:w-[33%]">
            <div className="storytext w-[100%] text-center">
              <p className="text-start">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
            </div>
            <div className="storyimage flex justify-evenly items-center mt-3">
              <div className="circle  w-[100px] h-[100px] rounded-[25px]">
                <img src={circle2} width="100%" height="100%" alt="" />
              </div>
              <div className="imagestory">
                <h3 className="text-md font-bold">Gabby Smith</h3>
                <p className="mt-2">Customer</p>
              </div>
            </div>
          </div>
          <div className="story flex flex-col w-[100%] md:w-[33%]">
            <div className="storytext w-[100%] text-center">
              <p className="text-start">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
            </div>
            <div className="storyimage flex justify-evenly items-center mt-3 ">
              <div className="circle  w-[100px] h-[100px] rounded-[25px]">
                <img src={circle1} width="100%" height="100%" alt="" />
              </div>
              <div className="imagestory">
                <h3 className="text-md font-bold">Floyd Weather</h3>
                <p className="mt-2">Customer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stories;
