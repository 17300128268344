import React from "react";
import c1 from "../images/c1.png";
import c2 from "../images/c2.png";
import c3 from "../images/c3.png";
import c4 from "../images/c4.png";

const Fanny = () => {
  return (
    <>
      <div className="bg-bgsecond bg-cover mt-12 flex justify-center items-center h-[45vh] w-[100%] text-white relative">
        <div className="maintext items-center p-2 md:w-[40%] w-[100%]">
          <h1 className="num text-yellow w-fit mx-auto text-2xl font-bold">
            99
          </h1>
          <p className="w-fit mx-auto mt-2">Fanny Spencer</p>
          <p className="w-fit mx-auto mt-2 items-center">
            As conscious traveling Paup ers we must always be oncerned about our
            dear Mother Earth. If you think about it, you travel across her face
            and She is the host to your journey.
          </p>
        </div>
        <div className="fourimage flex absolute bottom-2">
          <div className="circleone w-[50px] h-[50px] rounded-[25px] ml-[5px]">
            <img src={c1} width="50px" alt="" />
          </div>
          <div className="circleone w-[50px] h-[50px] rounded-[25px] ml-[5px]">
            <img src={c2} width="50px" alt="" />
          </div>
          <div className="circleone w-[50px] h-[50px] rounded-[25px] ml-[5px]">
            <img src={c3} alt="" width="50px" />
          </div>
          <div className="circleone w-[50px] h-[50px] rounded-[25px] ml-[5px]">
            <img src={c4} alt="" width="50px" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Fanny;
