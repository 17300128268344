import React from "react";
import logo from "../images/logo.png";

const Navbar = () => {
  return (
    <div className="bg-bgbakgound  bg-cover h-[85vh] border-b-2 border-red w-full bg-bottom">
      {/* <div className="navbar flex items-center justify-evenly min-w-[80%] mx-auto">
        <div className="logo">
          <div className="square bg-pinkdark text-white flex flex-col items-center p-2">
            <img src={logo} width="50px" alt="" />
            <h3 className="font-bold">MUSCLE</h3>
          </div>
          <div id=""></div>
          <div className="triangle-down w-[100px] h-[-8px] border-l-[49px] border-l-transparent  border-t-[19px]  border-t-pinkdark border-r-[49px] border-r-transparent"></div>
        </div>
        <div className="nav_list md:visible invisible ">
          <ul className=" text-white gap-5 justify-between text-[13px] flex">
            <li className="color text-pinkdark">HOME</li>
            <li>PROGRAM</li>
            <li>COACHES</li>
            <li>SCHEDULE</li>
            <li>ABOUT</li>
            <li>BLOG </li>
            <li>CONTACT</li>
          </ul>
        </div>
        <div className="menu ">
          <i className="bi bi-list"></i>
        </div>
      </div> */}

      <div className="center_text text-white flex flex-col items-center">
        <div className="text font-bold mt-[10rem] items-center">
          <h1 className="text-4xl font-bold text-center">CHALLENGE</h1>
          <h1 className="text-3xl font-bold  text-center mt-3">YOURSELF</h1>
          <p className="mt-3 text-md text-center  font-semibold ">
            G e t Y o u r B o d y F i t
          </p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
