import React from "react";

const Centermulty = () => {
  return (
    <>
      <div className="centerimage bg-centerdiv min-h-[250px] bg-cover flex w-[100%] justify-center items-center">
        <div className="value flex flex-wrap md:flex-nowrap justify-evenly text-white w-full items-center ">
          <div className="one relative w-[300px] text-center mt-2">
            <h1 className="text-2xl font-bold">2905</h1>
            <p    >Happy Coustomers</p>
          </div>
          <div className="one relative w-[300px] text-center mt-2">
            <h1 className="text-2xl font-bold">2648</h1>
            <p>Perfect Bodies</p>
          </div>
          <div className="one relative w-[300px] text-center mt-2">
            <h1 className="text-2xl font-bold">331</h1>
            <p>Working Hours</p>
          </div>
          <div className="one relative w-[300px] text-center mt-2">
            <h1 className="text-2xl font-bold">522</h1>
            <p>Success Stories</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Centermulty;
