import React from "react";
import first from "../images/first.png";
import second from "../images/second.png";
import third from "../images/third.png";
import fourth from "../images/fourth.png";
import j from "../images/j.png";
import k from "../images/k.png";
import l from "../images/l.png";
import m from "../images/m.png";

const Types = () => {
  return (
    <>
      <div className="mx-auto w-[80%] ">
        <div className="text items-center mt-12 ">
          <h1 className=" items-center mx-auto mt-3 w-fit text-4xl font-bold">
            What We Can Do for You
          </h1>
          <p className=" mt-3 w-fit text-sm items-center mb-2">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua ad minim
            veniam
          </p>
        </div>
        <div className="displya pl-[17%] pr-[17%] flex sm:flex-nowrap flex-wrap gap-8 justify-between">
          <div className="imgdiv">
            <img src={first} alt="" className="w-[160px]" />
          </div>
          <div className="imgdiv">
            <img src={second} className="w-[160px]" alt="" />
          </div>
          <div className="imgdiv">
            <img src={third} alt="" className="w-[160px]" />
          </div>
          <div className="imgdiv">
            <img src={fourth} alt="" className="w-[160px]" />
          </div>
        </div>
      </div>
      {/* select your style */}
      <div className="h-[55vh] bg-bgcenter bg-cover flex items-center mt-12">
        <div className="newbox w-[220px] h-[320px] bg-white md:ml-[26%] ml-auto mr-auto items-center text-sm p-2">
          <h1 className="text-xl font-bold items-center w-fit mx-auto">
            Select Your Style
          </h1>
          <h2 className="special mt-3 text-yellow text-lg font-bold items-center w-fit mx-auto">
            S h a v e i n g
          </h2>

          <div className="side flex justify-between mt-3">
            <div className="divimages w-[50px] h-[50px] hover:border-b-2 hover:border-yellow">
              <img src={j} alt="" width="100%" />
            </div>
            <div className="divimages w-[50px] h-[50px] hover:border-b-2 hover:border-yellow">
              <img src={k} width="100%" alt="" />
            </div>
            <div className="divimages w-[50px] h-[50px] hover:border-b-2 hover:border-yellow">
              <img src={l} width="100%" alt="" />
            </div>
            <div className="divimages w-[50px] h-[50px] hover:border-b-2 hover:border-yellow">
              <img src={m} width="100%" alt="" />
            </div>
          </div>
          <p className="mt-3 text-[12px]">
            inappropriate behavior is often laughed off as “boys will be boys,”
            women face higher conduct standards especially in the workplace.
            That’s why it’s crucial that as women.
          </p>
          <h3 className="w-fit mx-auto text-xl mt-2">V i e w G a l l e r y</h3>
        </div>
      </div>
      {/* end select your style */}
    </>
  );
};

export default Types;
