import React from "react";
import gymone from "../images/gymone.png";
import gymtwo from "../images/gymtwo.png";
import gymthree from "../images/gymthree.png";

const Blog = () => {
  return (
    <div>
      <div className="mt-12 text-center">
        <h4 className="text-lg">A r t i c l e s</h4>
        <h1 className="text-4xl mt-4">Recent Blog</h1>
      </div>
      <div className="flex flex-wrap md:flex-nowarp justify-evenly gap-16 items-center">
        <div className="one relative md:w-[30%] w-[100%] p-4">
          <img src={gymone} alt="img" className="w-full" />
          {/* <div className="onetop w-[300px] h-[300px] absolute "></div> */}
          <div className=" p-2 right-0 bottom absolute bottom-[-50px] bg-white left-9">
            <p className="text-[13px] font-heading">
              December 23, 2018 Admin  3
            </p>
            <h1 className="text-md font-bold mt-2">
              Young Women Doing Abdominal
            </h1>
            <p className="mt-2 text-[13px] font-heading">
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia
            </p>
          </div>
        </div>
        <div className="one relative md:w-[30%] w-[100%] p-4">
          <img src={gymtwo} alt="img" className="w-full" />
          {/* <div className="twotop"></div> */}
          <div className=" p-2 right-0 bottom absolute bottom-[-50px] bg-white left-9">
            <p className="text-[13px] font-heading">
              December 23, 2018 Admin  3
            </p>
            <h1 className="text-md font-bold mt-2">
              Young Women Doing Abdominal
            </h1>
            <p className="mt-2 text-[13px] font-heading">
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia
            </p>
          </div>
        </div>
        <div className="one relative md:w-[30%] w-[100%] p-4">
          <img src={gymthree} alt="img" className="w-full" />
          {/* <div className="threetop"></div> */}
          <div className=" p-2 right-0 bottom absolute bottom-[-50px] bg-white left-9">
            <p className="text-[13px] font-heading">
              December 23, 2018 Admin  3
            </p>
            <h1 className="text-md font-bold mt-2">
              Young Women Doing Abdominal
            </h1>
            <p className="mt-2 text-[13px] font-heading">
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
