import React from "react";
import p from "../images/p.png";
import q from "../images/q.png";
import r from "../images/r.png";
import s from "../images/s.png";

const Blog = () => {
  return (
    <>
      <div className="container8 w-[80%] mx-auto">
        <div className="texthead items-center pl-[15%] pr-[15%] mt-[70px] ">
          <h1 className="text-3xl w-fit mx-auto font-bold">Latest From Blog</h1>
          <p className="w-fit mx-auto items-center mt-3">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua ad minim
            veniam
          </p>
        </div>

        {/* OLD  */}

        <div className="containdiv flex flex-wrap gap-5 mt-8">
          <div className="flexitem flex md:flex-nowrap flex-wrap   w-[45%]   text-color bg-dark p-3">
            <div className="imgages p-4">
              <img className="imge w-[120px] " src={p} alt="" />
            </div>
            <div className="sidesection text-white p-4">
              <h3 className="text-lg font-bold">Portable Fashion for women</h3>
              <p className="text-[12px]">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt
              </p>
            </div>
          </div>
          <div className="flexitem flex  md:flex-nowrap flex-wrap w-[45%]  text-color bg-dark p-3">
            <div className="imgages p-4">
              <img className="imge w-[120px]  " src={q} alt="" />
            </div>
            <div className="sidesection text-white p-4">
              <h3 className="text-lg font-bold">Portable Fashion for women</h3>
              <p className="text-[12px]">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt
              </p>
            </div>
          </div>
          <div className="flexitem flex md:flex-nowrap flex-wrap  w-[45%]  text-color bg-dark p-3">
            <div className="imgages p-4">
              <img className="imge w-[120px]  " src={r} alt="" />
            </div>
            <div className="sidesection text-white p-4">
              <h3 className="text-lg font-bold">Portable Fashion for women</h3>
              <p className="text-[12px]">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
          </div>
          <div className="flexitem flex md:flex-nowrap flex-wrap w-[45%]  text-color bg-dark p-3">
            <div className="imgages p-4">
              <img className="imge w-[120px]" src={s} width="110px" alt="" />
            </div>
            <div className="sidesection text-white p-4">
              <h3 className="text-lg font-bold">Portable Fashion for women</h3>
              <p className="text-[12px]">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-center items-center">
        <div className="flex md:flex-row flex-wrap  max-w-[900px] gap-10 ">
          <div
            className="flex md:flex-row flex-col bg-black text-white w-[400px]
             gap-10 p-5 "
          >
            <div>
              <img src={p} alt="" className="w-[200px] object-cover " />
            </div>
            <div>
              <h1>Portable Fashion for women</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt
              </p>
            </div>
          </div>

          <div className="flex md:flex-row bg-black text-white  w-[400px] gap-10 p-5">
            <div>
              <img src={p} alt="" className="w-[200px] object-cover" />
            </div>
            <div>
              <h1>Portable Fashion for women</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt
              </p>
            </div>
          </div>

          <div className="flex md:flex-row bg-black text-white w-[400px] gap-10 p-5">
            <div>
              <img src={p} alt="" className="w-[200px] object-cover" />
            </div>
            <div>
              <h1>Portable Fashion for women</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt
              </p>
            </div>
          </div>

          <div className="flex md:flex-row bg-black text-white w-[400px] gap-10 p-5">
            <div>
              <img src={p} alt="" className="w-[200px] object-cover" />
            </div>
            <div>
              <h1>Portable Fashion for women</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Blog;
